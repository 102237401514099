<template>
    <Menubar :model="items">
      <template #start>
        <div class="mr-2">
          <i class="fa-solid fa-chart-pie fa-2xl"></i>
          Budget
        </div>
      </template>
      <template #item="{ item, props }">
        <router-link class="p-menuitem-active" v-slot="{ href, navigate, isActive }" :to="item.route" custom>
          <a :href="href" :class="{ 'router-link-active': isActive }" v-bind="props.action" @click="navigate">
            {{ item.label }}
          </a>
        </router-link>
      </template>
      <template #end>
        <router-link v-if="!authStore.isAuth" active-class="none" exact-active-class="none" class="p-button no-underline" to="/login">
          Login
        </router-link>
        <router-link v-else class="p-button no-underline" to="/logout">
          Logout
        </router-link>
      </template>
    </Menubar>
</template>
<script lang="ts" setup>
import Menubar from 'primevue/menubar';
import { MenuItem } from 'primevue/menuitem';

import { useAuthStore } from "../stores/auth.store";
import { computed } from 'vue';

const authStore = useAuthStore();

const items = computed<MenuItem>(() => authStore.isAuth ? [
  {
    label: "Transactions",
    route: "/transactions"
  },
  {
    label: "Filter",
    route: "/filter"
  },
  {
    label: "Budget",
    route: "/budget"
  },
  {
    label: "Import",
    route: "/import"
  }
]: [

]);
</script>
<style scoped>
  .router-link-active {
    background-color: #e5e7eb;
    border-radius: 6px;
  }
</style>