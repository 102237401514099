import { RouteLocationNormalizedLoaded } from "vue-router";

export function queryParamToString(route: RouteLocationNormalizedLoaded, path: string): string | undefined {
  const value = route.query[path]; 
  if(value instanceof Array && value.length > 0 && typeof value[0] === "string") {
    return value[0];
  } else if(typeof value === "string") {
    return value;
  }
}

export function dateFormat(date: string | Date, format: string): string {
  if(typeof date === "string") {
    date = new Date(date);
  }
  return format
    .replace("YYYY", padLeft(date.getFullYear(), 4, "0"))
    .replace("MM", padLeft(date.getMonth() + 1, 2, "0"))
    .replace("DD", padLeft(date.getDate(), 2, "0"))
    .replace("hh", padLeft(date.getHours(), 2, "0"))
    .replace("mm", padLeft(date.getMinutes(), 2, "0"))
    .replace("ss", padLeft(date.getSeconds(), 2, "0"));
}

export function swedishKronerFormat(value: number): string {
  const integer = Math.floor(value);
  const decimal = Math.round((value - integer) * 100);
  return thousandSeparator(integer.toString(), " ", 3) + "," + padLeft(decimal, 2, "0") + " kr";
}

function thousandSeparator(value: string, separator: string, groupSize: number): string {
  const parts: string[] = [];
  for(let i = value.length; i > 0; i -= groupSize) {
    parts.push(value.substring(Math.max(0, i - groupSize), i));
  }
  return parts.reverse().join(separator);
}

export function padLeft(value: number, length: number, padChar: string): string {
  return value.toString().padStart(length, padChar);
}

export function firstDateOfMonth(date: Date | string): Date {
  if(typeof date === "string") {
    date = new Date(date);
  }
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function lastDateOfMonth(date: Date | string): Date {
  if(typeof date === "string") {
    date = new Date(date);
  }
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  date.setHours(23, 59, 59, 999);
  return date;
}

export function beginingOfDay(date: Date): Date {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

export function csvToJson<T>(csv: string, { separator = ";", startAtRow = 1}: { separator?: string, startAtRow?: number } = {}): T[] {
  startAtRow = startAtRow - 1;
  const rows = csv.split("\n");
  const headers = rows[startAtRow].slice(1, -1).split(`"${separator}"`);
  const data: T[] = [];
  for(let i = startAtRow + 1; i < rows.length; i++) {
    const row = rows[i];
    if(row.length === 0) {
      continue;
    }
    const values = row.slice(1, -1).split(`"${separator}"`);
    const obj: Record<string, string> = {};
    for(let j = 0; j < headers.length; j++) {
      obj[headers[j]] = values[j];
    }
    data.push(obj as T);
  }
  
  return data;
}
