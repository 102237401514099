<template>
  <layout-component>
    <div class="card">
        <DataTable v-model:expandedRowGroups="expandedRowGroups" :value="transactions" tableStyle="min-width: 50rem"
                expandableRowGroups rowGroupMode="subheader" groupRowsBy="category"
                sortMode="single" sortField="category" :sortOrder="1">
            <template #groupheader="slotProps">
                <span class="vertical-align-middle ml-2 font-bold line-height-3">{{ slotProps.data.category }}</span>
            </template>
            <Column field="category" header="Category"></Column>
            <Column field="label" header="Label" style="width: 20%"></Column>
            <Column field="amount" header="Amount" style="width: 20%"></Column>
            <Column field="date" header="Date" style="width: 20%"></Column>
            <template #groupfooter="slotProps">
                <div class="flex justify-content-end font-bold w-full">Total Customers: {{ calculateCustomerTotal(slotProps.data.representative.name) }}</div>
            </template>
        </DataTable>
        <Toast />
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import { transactionDTO } from '../../../shared/dto/transaction.dto';
import { useTransactionsStore } from '../stores/transaction.store';


const transactionStore = useTransactionsStore();

const transactions = ref<transactionDTO[]>([]);
const expandedRowGroups = ref();

onMounted(async () => {
  transactions.value = await transactionStore.getTransactions({ from: new Date("2023-12-01"), to: new Date("2023-12-31") });
});
</script>
