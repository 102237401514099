import { defineStore } from "pinia";
import { transactionCreateDTO, transactionDTO, transactionUpdateDTO } from "../../../shared/dto/transaction.dto";
import { beginingOfDay, csvToJson } from "../utils";
import { useSurrealdbConnectionStore } from "./surrealdb-connection.store";

type lfTransaction = {
  Bokföringsdatum: string;
  Meddelande: string;
  Belopp: string;
}

export const useTransactionsStore = defineStore("transactions", () => {
  const surrealdbConnectionStore = useSurrealdbConnectionStore();

  async function getTransactions({ from, to, tags = [], categories = [] }: { from: Date, to: Date, tags?: string[], categories?: string[] }): Promise<transactionDTO[]> {
    const results = await surrealdbConnectionStore.db.query<transactionDTO[][]>(`
      SELECT * FROM transaction
      WHERE date >= "${from.toISOString()}"
      AND date <= "${to.toISOString()}"
      ${tags.length > 0 ? `AND tags CONTAINSANY ${JSON.stringify(tags)}` : ""}
      ${categories.length > 0 ? `AND ${JSON.stringify(categories)} CONTAINS category` : ""}
    `);
    return results[0];
  }

  async function getTransaction(transactionId: string): Promise<transactionDTO | undefined> {
    const results = await surrealdbConnectionStore.db.query<transactionDTO[][]>(`
      SELECT * FROM transaction
      WHERE id = "${transactionId}"
    `);
    return results[0][0];
  }

  async function getTags({ from, to }: { from?: Date, to?: Date } = {}): Promise<string[]> {
    const whereQuery: string[] = [];
    if(from) {
      whereQuery.push(`date >= "${from.toISOString()}"`);
    }
    if(to) {
      whereQuery.push(`date <= "${to.toISOString()}"`);
    }
    const results = await surrealdbConnectionStore.db.query<{ tags: string[] }[][]>(`
      SELECT
        array::group(tags) as tags
      FROM transaction
      ${whereQuery.length > 0 ? `WHERE ${whereQuery.join(" AND ")}` : ""}
      GROUP ALL
    `);

    if(results[0].length === 0) {
      return [];
    }

    return results[0][0].tags.filter(tag => tag !== null);
  }

  async function getCategories({ from, to }: { from?: Date, to?: Date } = {}): Promise<string[]> {
    const whereQuery: string[] = [];
    if(from) {
      whereQuery.push(`date >= "${from.toISOString()}"`);
    }
    if(to) {
      whereQuery.push(`date <= "${to.toISOString()}"`);
    }
    const results = await surrealdbConnectionStore.db.query<{ category: string[] }[][]>(`
      SELECT
        array::group(category) as category
      FROM transaction
      ${whereQuery.length > 0 ? `WHERE ${whereQuery.join(" AND ")}` : ""}
      GROUP ALL
    `);

    if(results[0].length === 0) {
      return [];
    }

    return results[0][0].category.filter(tag => tag !== null);
  }

  async function createTransaction(transaction: transactionCreateDTO): Promise<void> {
    await surrealdbConnectionStore.db.query(`
      INSERT INTO transaction ${JSON.stringify(transaction)}
    `);
  }


  async function updateTransaction(transactionId: string, transaction: transactionUpdateDTO): Promise<void> {
    await surrealdbConnectionStore.db.update(transactionId, transaction);
  }

  async function importToDb(csv: string, progressCallback?: (percentage: number) => void): Promise<{ importedRows: number, totalRows: number }> {
    const transactions = csvToJson<lfTransaction>(csv, { startAtRow: 4 });
    let importedRows = 0;
    let counter = 0;
    for(const transaction of transactions) {
      const data: transactionCreateDTO = {
        date: beginingOfDay(new Date(transaction["Bokföringsdatum"])).toISOString(),
        amount: parseInt(transaction.Belopp.replace(",", ".").replace(" ", ""), 10) * -1,
        label: transaction.Meddelande,
        category: "Okategoriserad",
        originalMessageText: transaction.Meddelande,
      };
      if(data.amount > 0 && data.label !== "Överföring") {
        const result = await surrealdbConnectionStore.db.query<[null, transactionDTO | null]>(`
        LET $transaction = SELECT COUNT() FROM transaction WHERE date = "${data.date}" AND amount = ${data.amount} AND originalMessageText = "${data.originalMessageText}";
        IF count($transaction) = 0 THEN
        INSERT INTO transaction ${JSON.stringify(data)}
        END
        `);
        
        if(result[1] !== null) {
          importedRows++;
        }
      }
      progressCallback?.(Math.round(++counter / transactions.length * 100));
    }

    return { importedRows, totalRows: transactions.length };
  }

  return { getTransactions, getTransaction, getTags, getCategories, createTransaction, updateTransaction, importToDb };
});

