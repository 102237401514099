<template>
<span class="p-buttonset">
  <Button icon="pi pi-chevron-left" @click="previousPeriod" />
  <Button :label="period || ''" @click="resetPeriod" />
  <Button icon="pi pi-chevron-right" @click="nextPeriod" />
</span>
</template>
<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Button from 'primevue/button';
import { padLeft, queryParamToString } from '../utils';

const router = useRouter();
const route = useRoute();

const period = computed<string | null>(() => queryParamToString(route, "period") || null);

onMounted(() => {
  if(period.value === null) {
    resetPeriod();
  }
});

function previousPeriod() {
  if(period.value === null) {
    return;
  }
  const date = new Date(period.value);
  date.setMonth(date.getMonth() - 1);
  router.push({ query: { period: getPeriodFromDate(date) } });
}
function resetPeriod() {
  router.push({ query: { period: getPeriodFromDate(new Date()) } });
}
function nextPeriod() {
  if(period.value === null) {
    return;
  }
  const date = new Date(period.value);
  date.setMonth(date.getMonth() + 1);
  router.push({ query: { period: getPeriodFromDate(date) } });}

function getPeriodFromDate(date: Date) {
  return `${date.getFullYear()}-${padLeft(date.getMonth() + 1, 2, "0")}`;
}

</script>