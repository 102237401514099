import { defineStore } from "pinia";
import { computed } from "vue";
import { useSurrealdbConnectionStore } from "./surrealdb-connection.store";
import { useTokenStore } from "./token.store";

export const useAuthStore = defineStore("auth", () => {
  const tokenStore = useTokenStore();
  const surrealdbStore = useSurrealdbConnectionStore();

  const isAuth = computed(() => {
    return !!tokenStore.token;
  });

  async function login(email: string, password: string): Promise<void> {
    const token = await surrealdbStore.db.signin({
      namespace: process.env.SURREALDB_NAMESPACE,
      database: process.env.SURREALDB_DATABASE,
      scope: "user",
      email,
      password,
    });

    tokenStore.setToken(token);
  }

  async function logout(): Promise<void> {
    await surrealdbStore.db.invalidate();
    tokenStore.clearToken();
  }

  return { isAuth, login, logout };
});