import BaseStyle from 'primevue/base/style';

var css = "\n@layer primevue {\n    .p-fileupload-content {\n        position: relative;\n    }\n\n    .p-fileupload-content .p-progressbar {\n        width: 100%;\n        position: absolute;\n        top: 0;\n        left: 0;\n    }\n\n    .p-button.p-fileupload-choose {\n        position: relative;\n        overflow: hidden;\n    }\n\n    .p-fileupload-buttonbar {\n        display: flex;\n        flex-wrap: wrap;\n    }\n\n    .p-fileupload > input[type='file'],\n    .p-fileupload-basic input[type='file'] {\n        display: none;\n    }\n\n    .p-fluid .p-fileupload .p-button {\n        width: auto;\n    }\n\n    .p-fileupload-file {\n        display: flex;\n        flex-wrap: wrap;\n        align-items: center;\n    }\n\n    .p-fileupload-file-thumbnail {\n        flex-shrink: 0;\n    }\n\n    .p-fileupload-file-actions {\n        margin-left: auto;\n    }\n}\n";
var classes = {
  root: function root(_ref) {
    var props = _ref.props;
    return ["p-fileupload p-fileupload-".concat(props.mode, " p-component")];
  },
  buttonbar: 'p-fileupload-buttonbar',
  chooseButton: function chooseButton(_ref2) {
    var instance = _ref2.instance,
      props = _ref2.props;
    return ['p-button p-component p-fileupload-choose', {
      'p-fileupload-choose-selected': props.mode === 'basic' && instance.hasFiles,
      'p-disabled': props.disabled,
      'p-focus': instance.focused
    }];
  },
  chooseIcon: 'p-button-icon p-button-icon-left',
  chooseButtonLabel: 'p-button-label',
  content: 'p-fileupload-content',
  empty: 'p-fileupload-empty',
  uploadIcon: 'p-button-icon p-button-icon-left',
  label: 'p-button-label',
  file: 'p-fileupload-file',
  thumbnail: 'p-fileupload-file-thumbnail',
  details: 'p-fileupload-file-details',
  fileName: 'p-fileupload-file-name',
  fileSize: 'p-fileupload-file-size',
  badge: 'p-fileupload-file-badge',
  actions: 'p-fileupload-file-actions',
  removeButton: 'p-fileupload-file-remove'
};
var FileUploadStyle = BaseStyle.extend({
  name: 'fileupload',
  css: css,
  classes: classes
});

export { FileUploadStyle as default };
