import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import DialogService from "primevue/dialogservice";
import { createApp } from "vue";


import AppComponent from "./components/app.component.vue";
import { router } from "./router";

import { useSurrealdbConnectionStore } from "./stores/surrealdb-connection.store";
import { useTokenStore } from "./stores/token.store";

const pinia = createPinia();
const app = createApp(AppComponent);

app.use(pinia);
app.use(router);
app.use(PrimeVue);
app.use(DialogService);

const surrealdbConnectionStore = useSurrealdbConnectionStore();
const tokenStore = useTokenStore();

(async (): Promise<void> => {
  tokenStore.$onAction(({ name }) => {
    if(name === "clearToken") {
      router.push({ name: "login" });
    }
  });
  
  await surrealdbConnectionStore.connect();

  app.mount("#app");
})();