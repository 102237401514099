<template>
  <layout-component>
    <div class="w-11 mx-auto">
      <div class="flex mb-5 w-full">
        <div style="width: 33%">
          <Button icon="pi pi-plus" icon-pos="right" label="Add Transaction" @click="addTransaction" />
        </div>
        <div class="flex flex-grow-1 justify-content-center" style="width: 33%;">
          <transactions-period-component></transactions-period-component>
        </div>
        <div class="flex-grow-1" style="width: 33%;"></div>
      </div>
      <div class="w-full flex align-items-center">
        <InputSwitch id="group-by-category" v-model="groupByCategory" />
        <label for="group-by-category" class="ml-2">Group by category</label>
      </div>
      <DataTable
      size="small"
        v-model:expandedRowGroups="expandedRowGroups"
        :expandableRowGroups="groupByCategory"
        :rowGroupMode="groupByCategory ? 'subheader' : undefined"
        :groupRowsBy="groupByCategory ? 'category' : undefined"
        filterDisplay="row"
        :sortField="groupByCategory ? 'category' : 'date'"
        :sortOrder="groupByCategory ? 1 : -1"
        :value="transactionDataRows"
        stripedRows>
        <template #groupheader="slotProps" v-if="groupByCategory">
            <span class="vertical-align-middle ml-2 font-bold line-height-3">{{ slotProps.data.category }}</span>
            <div style="float: right">
              {{ swedishKronerFormat(sumOfCategory(slotProps.data.category)) }}
            </div>
        </template>
        <Column :sortable="!groupByCategory" field="category" header="Category" />
        <Column :sortable="!groupByCategory" field="date" header="Date">
          <template #body="slotProp">
            {{ dateFormat(slotProp.data.date, "YYYY-MM-DD") }}
          </template>
        </Column>
        <Column :sortable="!groupByCategory" field="label" header="Label" />
        <Column :sortable="!groupByCategory" field="amount" header="Amount">
          <template #body="{ data }">
            {{ swedishKronerFormat(data.amount) }}
          </template>
        </Column>
        <!-- <Column field="tags" header="Tags" :showFilterMenu="false">
          <template #body="{ data }">
            <Chip v-for="tag in data.tags" :key="tag" :label="tag" class="mr-2" />
          </template>
        </Column> -->
        <Column header="Actions" class="flex justify-content-end gap-2">
          <template #body="{ data }">
            <Button severity="info" @click="editTransaction(data.id)" icon="pi pi-pencil" size="small" class="p-0" />
            <Button severity="danger" @click="deleteTransaction(data.id)" icon="pi pi-trash" size="small" />
          </template>
        </Column>
        <template #empty>
          <div class="flex justify-content-center"><i>No transactions found</i></div>
        </template>
      </DataTable>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
import { onMounted, ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { LiveQueryResponse } from 'surrealdb.js/script/types';
import Chip from 'primevue/chip';
import Button from 'primevue/button';
import { useDialog } from 'primevue/usedialog';
import InputSwitch from 'primevue/inputswitch';

import LayoutComponent from './layout.component.vue';
import TransactionsPeriodComponent from './transactions-period.component.vue';
import TransactionFormComponent from './transaction-form.component.vue';
import TransactionDeleteComponent from './transaction-delete.component.vue';

import { useSurrealdbConnectionStore } from '../stores/surrealdb-connection.store';
import { queryParamToString, dateFormat, swedishKronerFormat, firstDateOfMonth, lastDateOfMonth } from '../utils';
import { transactionDTO } from '../../../shared/dto/transaction.dto';

const route = useRoute();
const dialog = useDialog();
const db = useSurrealdbConnectionStore().db;

const query = computed<string | null>(() => {
  if(period.value !== null) {
    return `SELECT * FROM transaction WHERE date >= '${firstDateOfMonth(period.value).toISOString()}' AND date <= '${lastDateOfMonth(period.value).toISOString()}'`;
  }

  return null;
});
const period = computed<string | null>(() => queryParamToString(route, "period") || null);

const groupByCategory = ref(false);
const transactions = ref<transactionDTO[]>([]);
const transactionDataRows = computed(() => transactions.value.map(transaction => ({ ...transaction, date: new Date(transaction.date) })));

const expandedRowGroups = ref();

const liveQueryId = ref<string | null>(null);

watch(query, query => {
  updateLiveQuery(query);
  getTransactions();
});
watch(liveQueryId, (liveQueryId, oldLiveQueryId) => {
  // Kill old live query
  if(oldLiveQueryId !== null) {
    db.kill(oldLiveQueryId);
  }
  if(liveQueryId === null) { return; }
  db.listenLive(liveQueryId, data => liveListner(data));
})

onMounted(async () => {
  updateLiveQuery(query.value);
  getTransactions();
});

async function getTransactions() {
  if(query.value === null) { return; }
  transactions.value = (await db.query(query.value))[0] as any;
}
function addTransaction() {
  dialog.open(TransactionFormComponent, {
    props: {
      header: "Add transaction",
      style: {
        width: "50vw"
      },
      modal: true,
      closeOnEscape: true,
    }
  });
}
function editTransaction(transactionId: string) {
  dialog.open(TransactionFormComponent, {
    props: {

      header: "Edit transaction",
      style: {
        width: "50vw"
      },
      modal: true,
      closeOnEscape: true,
    },
    data: {
      transactionId
    }
  });
}
function deleteTransaction(transactionId: string) {
  dialog.open(TransactionDeleteComponent, {
    props: {
      header: "Delete transaction",
      style: {
        width: "50vw"
      },
      modal: true,
      closeOnEscape: true,
    },
    data: {
      transactionId
    }
  });
}

async function updateLiveQuery(query: string | null) {
  if(query === null) { return; }
  // Start new live query
  const result: string[] = await db.query(`LIVE ${query}`);

  // Save live query id
  liveQueryId.value = result[0];
}

function liveListner(data: LiveQueryResponse) {
  if(data.action === "CREATE") {
    transactions.value.push(data.result as unknown as transactionDTO);
  } else if(data.action === "UPDATE") {
    const index = transactions.value.findIndex(transaction => transaction.id === data.result.id);
    transactions.value.splice(index, 1, data.result as unknown as transactionDTO);
  } else if(data.action === "DELETE") {
    const index = transactions.value.findIndex(transaction => transaction.id === (data.result as any));
    transactions.value.splice(index, 1);
  }
}

function sumOfCategory(category: string) {
  return transactions.value.filter(transaction => transaction.category === category).reduce((sum, transaction) => sum + transaction.amount, 0);
}
</script>