<template>
  <form class="grid" @submit.prevent="submit">
    <div class="field col-6">
      <label for="date">Date</label>
      <Calendar :disabled="loading" dateFormat="yy-mm-dd" :selectOtherMonths="true" showIcon class="w-full" id="date" v-model="date" />
    </div>
    <div class="field col-6">
      <label for="category">Category</label>
      <AutoComplete id="category" :disabled="loading" inputClass="w-full" class="w-full" v-model="category" :suggestions="searchResultCategories" @complete="searchCategory" />
    </div>
    <div class="field col-6">
      <label for="label">Label</label>
      <InputText :disabled="loading" class="w-full" id="label" v-model="label" />
    </div>
    <div class="field col-6">
      <label for="amount">Amount</label>
      <InputNumber :disabled="loading" class="w-full" v-model="amount" mode="currency" currency="SEK" locale="sv-SE" />
    </div>
    <div class="field col-12">
      <label for="originalMessageText">Orignal Message Text</label>
      <InputText :disabled="loading" class="w-full" id="originalMessageText" v-model="originalMessageText" />
    </div>
    <div class="field col-12">
      <label for="tags">Tags</label>
      <AutoComplete id="tags" :disabled="loading" multiple class="w-full" v-model="tags" :suggestions="searchResultTags" @complete="searchTags" />
    </div>
    <div class="field col-12">
      <label for="description">Description</label>
      <Textarea :disabled="loading" class="w-full" rows="5" id="description" v-model="description" />
    </div>
    <div class="field col-12 flex justify-content-end gap-2">
      <Button type="submit" :disabled="loading || !isValid">
        <template v-if="!loading">
          <template v-if="transactionId">Update</template>
          <template v-else>Add</template>
        </template>
        <i v-else class="fa-solid fa-spinner fa-spin-pulse"></i>
      </Button>
      <button type="button" :disabled="loading" class="p-button p-button-secondary" @click="cancel">
        Cancel
      </button>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { computed, inject, onMounted, ref } from 'vue';

import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';

import { useSurrealdbConnectionStore } from '../stores/surrealdb-connection.store';
import { useTransactionsStore } from '../stores/transaction.store';
import { beginingOfDay } from '../utils';

const db = useSurrealdbConnectionStore().db;
const transactionsStore = useTransactionsStore();

const dialogRef: any = inject('dialogRef');

const transactionId = computed(() => dialogRef.value.data?.transactionId);

const loading = ref(false);

const label = ref('');
const date = ref<Date>(new Date());
const amount = ref();
const tags = ref<string[]>([]);
const category = ref<string>("");
const description = ref<string>("");
const originalMessageText = ref<string>("");
const existingTags = ref<string[]>([]);
const searchResultTags = ref<string[]>([]);
const existingCategories = ref<string[]>([]);
const searchResultCategories = ref<string[]>([]);

const isValid = computed(() => {
  return label.value.trim().length > 0 && amount.value > 0 && category.value.trim().length > 1;
});

function searchTags(event) {
  searchResultTags.value = [...(!existingTags.value.includes(event.query.toLowerCase()) ? [event.query.toLowerCase()] : []), ...existingTags.value.filter(tag => tag.toLowerCase().includes(event.query.toLowerCase()))];
}

function searchCategory(event) {
  searchResultCategories.value = existingCategories.value.filter(category => category.toLowerCase().includes(event.query.toLowerCase()));
}

onMounted(async () => {
  loading.value = true;

  existingTags.value = await transactionsStore.getTags();
  existingCategories.value = await transactionsStore.getCategories();

  if(transactionId.value) {
    const transaction = await transactionsStore.getTransaction(transactionId.value);
    if(transaction) {
      label.value = transaction.label;
      date.value = new Date(transaction.date);
      amount.value = transaction.amount;
      tags.value = transaction.tags || [];
      category.value = transaction.category;
      description.value = transaction.description || "";
      originalMessageText.value = transaction.originalMessageText || "";
    }
  }

  loading.value = false;
});

async function submit() {
  loading.value = true;
  if(transactionId.value) {
    transactionsStore.updateTransaction(transactionId.value, {
      label: label.value,
      date: beginingOfDay(date.value).toISOString(),
      amount: amount.value,
      ...(tags.value.length > 0 ? { tags: tags.value } : {}),
      category: category.value,
      ...(originalMessageText.value?.trim() ? { originalMessageText: originalMessageText.value } : {}),
      ...(description.value?.trim() ? { description: description.value } : {})
    });
  } else {
    await transactionsStore.createTransaction({
      label: label.value,
      date: beginingOfDay(date.value).toISOString(),
      amount: amount.value,
      ...(tags.value.length > 0 ? { tags: tags.value } : {}),
      category: category.value,
      ...(originalMessageText.value?.trim() ? { originalMessageText: originalMessageText.value } : {}),
      ...(description.value?.trim() ? { description: description.value } : {})
    });
  }
  dialogRef.value.close();
}

function cancel() {
  dialogRef.value.close();
}
</script>
<style>
  .p-autocomplete > ul {
    width: 100%;
  }
</style>