<template>
  <div>
    <p>Are you sure you want to delete this transaction?</p>
    <div class="flex justify-content-end gap-2">
      <Button :disabled="loading" severity="danger" @click="deleteTransaction">
        <template v-if="!loading">
          Delete
        </template>
        <i v-else class="fa-solid fa-spinner fa-spin-pulse"></i>
      </Button>
      <Button label="Cancel" :disabled="loading" severity="secondary" @click="cancel" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject, ref } from 'vue';
import Button from 'primevue/button';
import { useSurrealdbConnectionStore } from '../stores/surrealdb-connection.store';

const db = useSurrealdbConnectionStore().db;

const dialogRef: any = inject('dialogRef');

const loading = ref(false);

async function deleteTransaction() {
  await db.query(`DELETE ${dialogRef.value.data.transactionId}`);
  dialogRef.value.close();
}

function cancel() {
  dialogRef.value.close();
}

</script>