<template>
  <layout-component>
    <Panel class="w-5 mx-auto" header="Import">
      <div v-if="!done && !loading" class="flex justify-content-center">
        <FileUpload mode="basic" name="demo[]" accept="text/csv" :auto="true" @select="onSelect" />
      </div>
      <ProgressBar v-if="loading" :value="progress" />
      <Message v-if="done" severity="success" @close="done = false">
        Successfully imported {{ importedRows }} of {{ totalRows }} rows.
      </Message>
    </Panel>
  </layout-component>
</template>
<script lang="ts" setup>
import layoutComponent from './layout.component.vue';
import FileUpload, { FileUploadSelectEvent } from 'primevue/fileupload';
import Panel from 'primevue/panel';
import Message from 'primevue/message';
import ProgressBar from 'primevue/progressbar';

import { ref } from 'vue';
import { useTransactionsStore } from '../stores/transaction.store';

const transactionsStore = useTransactionsStore();

const loading = ref(false);
const progress = ref<number>();
const done = ref(false);
const importedRows = ref<number>();
const totalRows = ref<number>();

async function onSelect(event: FileUploadSelectEvent) {
  loading.value = true;
  const content = await event.files[0].text();
  const result = await transactionsStore.importToDb(content, (percentage) => {
    progress.value = percentage;
  });
  loading.value = false;
  done.value = true;
  importedRows.value = result.importedRows;
  totalRows.value = result.totalRows;
}
</script>
<style lang="scss">
.p-progressbar-determinate .p-progressbar-value-animate {
  transition: width 0s ease-in-out !important;
}
</style>