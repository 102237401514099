import { createRouter, createWebHistory } from "vue-router";

import FilterComponent from "./components/filter.component.vue";
import ImportComponent from "./components/import.component.vue";
import LoginComponent from "./components/login.component.vue";
import NotFoundComponent from "./components/not-found.component.vue";
import TestComponent from "./components/test.component.vue";
import TransactionsComponent from "./components/transactions.component.vue";

import * as middlewares from "./middlewares";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/transactions", name: "transactions", beforeEnter: middlewares.isAuth, component: TransactionsComponent },
    { path: "/test", name: "test", beforeEnter: middlewares.isAuth, component: TestComponent },
    { path: "/filter", name: "filter", beforeEnter: middlewares.isAuth, component: FilterComponent },
    { path: "/import", name: "import", beforeEnter: middlewares.isAuth, component: ImportComponent },
    { path: "/login", name: "login", component: LoginComponent },
    { path: "/logout", name: "logout", component: { beforeRouteEnter: middlewares.logout } },
    { path: "/:pathMatch(.*)*", name: "not-found", component: NotFoundComponent, props: { code: 404 } }
  ]
});