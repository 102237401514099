<template>
  <DynamicDialog />
  <router-view></router-view>
</template>
<script lang="ts" setup>
import { RouterView, useRouter } from 'vue-router';
import DynamicDialog from 'primevue/dynamicdialog';
import { useTokenStore } from '../stores/token.store';

const router = useRouter();
const tokenStore = useTokenStore();
tokenStore.$onAction(({
  name,
}) => {
  console.log("ACTION", name);
  if(name === "clearToken") {
    router.push({ name: 'login' });
  }
})
</script>