import { defineStore } from "pinia";
import { Surreal } from "surrealdb.js";
import { useTokenStore } from "./token.store";

export const useSurrealdbConnectionStore = defineStore("surrealdb-connection", () => {
  const tokenStore = useTokenStore();
  
  const db = new Surreal();
  async function connect(): Promise<void> {
    await db.connect(process.env.SURREALDB_BASE_URL, {
      namespace: process.env.SURREALDB_NAMESPACE,
      database: process.env.SURREALDB_DATABASE
    });

    if(tokenStore.token !== null) {
      try {
        await db.authenticate(tokenStore.token);
      } catch(e) {
        tokenStore.clearToken();
      }
    }
  }

  async function disconnect(): Promise<void> {
    await db.close();
  }

  return { db, connect, disconnect };
});