<template>
  <layout-component>
    <Panel class="w-6 mx-auto">
      <template #header>Login</template>
      <form @submit.prevent="login">
        <div class="field">
          <label for="email">Email</label>
          <InputText :disabled="loading" :class="['w-full', { 'p-invalid': loginError }]" id="email" v-model="email" type="email" />
        </div>
        <div class="field">
          <label for="password">Password</label>
          <InputText :disabled="loading" type="password" :class="['w-full', { 'p-invalid': loginError }]" id="password" v-model="password" />
        </div>
        <Message v-if="loginError" severity="error" v-on:close="loginError = false">
          Invalid email or password
        </Message>
        <Button type="submit" :disabled="loading || !isValid">
          <template v-if="!loading">
            Login
          </template>
          <i v-else class="fa-solid fa-spinner fa-spin-pulse"></i>
        </Button>
      </form>
    </Panel>
  </layout-component>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';

import LayoutComponent from './layout.component.vue';

import { useAuthStore } from '../stores/auth.store';

const router = useRouter();

const loading = ref(false);
const loginError = ref(false);
const email = ref('');
const password = ref('');

const authStore = useAuthStore();

const isValid = computed(() => {
  return email.value.length > 0 && password.value.length > 0;
});

async function login() {
  loading.value = true;
  try {
    await authStore.login(email.value, password.value);
    router.push('/transactions');
  } catch (error) {
    loginError.value = true;
  } finally {
    loading.value = false;
  }
}
</script>