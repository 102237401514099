<template>
  <layout-component>
    <Panel class="w-full" header="Filter">
        <form>
          <div class="grid">
            <div class="field col-4">
              <label for="from">From</label>
              <Calendar class="w-full" id="from" v-model="from" :showIcon="true" dateFormat="yy-mm-dd" :monthNavigator="true" :yearNavigator="true" :showButtonBar="true" :selectOtherMonths="true" :disabled="loading" />
            </div>
            <div class="field col-4">
              <label for="to">To</label>
              <Calendar class="w-full" id="to" v-model="to" :showIcon="true" dateFormat="yy-mm-dd" :monthNavigator="true" :yearNavigator="true" :showButtonBar="true" :selectOtherMonths="true" :disabled="loading" />
            </div>
            <div class="field col-4">
              <label for="categories">Categories</label>
              <MultiSelect v-model="selectedCategories" :options="categories" filter :disabled="loading" optionLabel="name" placeholder="Select Category" class="w-full">
                <template #value="{ value }">
                  <span v-for="category in value" :key="category" class="p-tag mr-2" :style="{ 'background-color': hashStringToColor(category) }">{{ category }}</span>
                </template>
                <template #option="{ option }">
                  <span class="p-tag" :style="{ 'background-color': hashStringToColor(option) }">{{ option }}</span>
                </template>
              </MultiSelect>
            </div>
          </div>
        </form>
    </Panel>
    <div class="w-4 mx-auto">
      <Chart type="pie" :data="chartData" :options="chartOptions"></Chart>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import Calendar from 'primevue/calendar';
import Panel from 'primevue/panel';
import MultiSelect from 'primevue/multiselect';
import Chart from 'primevue/chart';
import { useRoute, useRouter } from 'vue-router';

import { useSurrealdbConnectionStore } from '../stores/surrealdb-connection.store';
import { useTransactionsStore } from '../stores/transaction.store';

import { dateFormat, firstDateOfMonth, lastDateOfMonth, queryParamToString, swedishKronerFormat } from '../utils';
import { transactionDTO } from '../../../shared/dto/transaction.dto';

import LayoutComponent from './layout.component.vue';

const route = useRoute();
const router = useRouter();

const db = useSurrealdbConnectionStore().db;
const transactionsStore = useTransactionsStore();

const queryFrom = computed<string | null>(() => queryParamToString(route, "from") || null);
const queryTo = computed<string | null>(() => queryParamToString(route, "to") || null);
const queryCategories = computed<string[] | null>(() => {
  const categories = queryParamToString(route, "categories");
  if(categories) {
    return categories.split(",");
  }
  return null;
});

const loading = ref(false);

const from = ref<Date>();
const to = ref<Date>();
const selectedCategories = ref<string[]>([]);

const categories = ref<string[]>([]);

const transactions = ref<transactionDTO[]>([]);
const transactionsCategories = computed<{ name: string, amount: number }[]>(() => {
  return selectedCategories.value.map(category => {
    const amount = transactions.value.filter(transaction => transaction.category === category).reduce((sum, transaction) => sum + transaction.amount, 0);
    return { name: category, amount };
  });
});

const chartData = computed(() => {
  return {
    labels: transactionsCategories.value.map(category => category.name),
    datasets: [
        {
            label: 'Amount',
            data: transactionsCategories.value.map(category => category.amount),
            backgroundColor: transactionsCategories.value.map(category => `${hashStringToColor(category.name)}`),
        }
    ]
  };
});

const chartOptions = computed(() => {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          label: (content) => swedishKronerFormat(content.parsed)
        }
      }
    }
  };
});

watch([from, to, selectedCategories], async () => {
  updateQuery();
  await loadCategories();
  await loadTransactions();
});

onMounted(async () => {
  if(queryFrom.value) {
    from.value = new Date(queryFrom.value);
  } else {
    from.value = firstDateOfMonth(new Date());
  }
  if(queryTo.value) {
    to.value = new Date(queryTo.value);
  } else {
    to.value = lastDateOfMonth(new Date());
  }
  if(queryCategories.value) {
    selectedCategories.value = queryCategories.value;
  }
});

function updateQuery() {
  const query: any = {};

  if(from.value) {
    query.from = dateFormat(from.value, "YYYY-MM-DD");
  }
  if(to.value) {
    query.to = dateFormat(to.value, "YYYY-MM-DD");
  }
  if(selectedCategories.value.length > 0) {
    query.categories = selectedCategories.value.join(",");
  }
  router.push({ query });
}

async function loadCategories() {
  loading.value = true;
  const loadedCategories = await transactionsStore.getCategories({ from: from.value, to: to.value });
  categories.value = loadedCategories;
  loading.value = false;
}

async function loadTransactions() {
  if(!from.value || !to.value || selectedCategories.value.length === 0) {
    return;
  }
  loading.value = true;
  transactions.value = await transactionsStore.getTransactions({ from: from.value, to: to.value, categories: categories.value });
  loading.value = false;
}

function djb2(value: string) {
  var hash = 5381;
  for (var i = 0; i < value.length; i++) {
    hash = ((hash << 5) + hash) + value.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
}

function hashStringToColor(value: string) {
  var hash = djb2(value);
  var r = (hash & 0xFF0000) >> 16;
  var g = (hash & 0x00FF00) >> 8;
  var b = hash & 0x0000FF;
  return "#" + ("0" + r.toString(16)).substr(-2) + ("0" + g.toString(16)).substr(-2) + ("0" + b.toString(16)).substr(-2);
}

</script>../stores/transaction.store